.MuiOutlinedInput-root {
    border-radius: 0 !important;
    background-color: white !important;
}

.MuiOutlinedInput-inputMarginDense{
    height: 30px !important;
}

.MuiButton-root {
    line-height: 28px !important;
    text-transform: none !important;
    border-radius: 0 !important;
}
